.errorbg {
    background-color:rgb(44, 44, 44);
    width:100vw;
    height:100vh;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.errortitle {
    color:rgb(255, 255, 255);
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.errorp {
    color:rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
}